<template>
  <container-list-extend title="Materiel">
    <template v-slot:title>
      <div class="p-1 flex-grow-1" v-if="materiel.description || materiel.identifiant">
        <div class="d-flex">
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-success mr-1"
            fill="#28a745"
            v-if="getLastVerifDate() >= $moment()"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-danger mr-1"
            fill="#dc3545"
            v-if="getLastVerifDate() < $moment()"
          ></BaseIcon>
          <span> {{ materiel.description }} n°{{ materiel.identifiant }} </span>
        </div>
        <div class="d-flex align-items-center text-muted">
          <div v-if="getLastVerifDate()">
            <small> Vérification valide jusqu'au {{ getLastVerifDate().format("DD/MM/YYYY") }} </small>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1 text-primary" v-else>
        Nouveau matériel
      </div>

      <!--<div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>{{ materiel.identifiant }}</small>
      </div>-->
    </template>
    <template v-slot:form>
      <div class="d-flex flex-column flex-lg-row">
        <base-form
          class="w-100"
          deleteBtn
          @click="updateMateriel(materiel)"
          @delete="deleteMateriel(materiel)"
          :statut="statut"
          :loading="loading"
        >
          <base-form-row row>
            <base-select
              inputText="Type"
              v-model.number="materiel.materiel_type_id"
              :options="data.materielTypes"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Etat"
              v-model.number="materiel.materiel_etat_id"
              :options="data.materielEtats"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Fournisseur"
              v-model.number="materiel.materiel_fournisseur_id"
              :options="fournisseurs"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Société"
              v-model.number="materiel.materiel_societe_id"
              :options="data.societes"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.identifiant"
              inputText="Identifiant"
              :errors="feedback.identifiant"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.description"
              inputText="Description"
              :errors="feedback.description"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.num_serie"
              inputText="N° de série"
              :errors="feedback.num_serie"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.lieu_stockage"
              inputText="Lieu de stockage"
              :errors="feedback.lieu_stockage"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.marque"
              inputText="Marque"
              :errors="feedback.marque"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="materiel.modele"
              inputText="Modèle"
              :errors="feedback.modele"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <base-inputDatePicker v-model="materiel.date_commande" inputText="Date de commande"></base-inputDatePicker>
          </base-form-row>
          <base-form-row row>
            <base-inputDatePicker
              v-model="materiel.date_livraison"
              inputText="Date de livraison"
            ></base-inputDatePicker>
          </base-form-row>
          <base-form-row row>
            <base-inputDatePicker
              v-model="materiel.date_validite"
              inputText="Date de validité (si applicable)"
            ></base-inputDatePicker>
          </base-form-row>
          <base-form-row row>
            <base-input-area v-model="materiel.commentaire" text="Commentaire"></base-input-area>
          </base-form-row>
        </base-form>
        <div class="pl-3 pr-3 w-100">
          <BaseToolBar title="Affectation" subtitle="Affecter le matériel au collaborateur (si applicable)">
          </BaseToolBar>
          <select-collaborateur
            class="mb-5"
            v-model.number="materiel.materiel_collab_id"
            :text="'Affecter à un collaborateur'"
          ></select-collaborateur>
          <BaseToolBar title="Vérifications" subtitle="Liste des vérification disponibles">
            <BaseButton
              class="btn btn-primary ml-3"
              v-tooltip="'Ajouter une vérification'"
              @click="storeMaterielVerif"
              :loading="loading"
              icon="plus"
            ></BaseButton>
          </BaseToolBar>
          <div v-for="verif in verifs" :key="verif.id">
            <materiels-item-verif :verif="verif"></materiels-item-verif>
          </div>
          <uploader
            v-if="materiel.id"
            class="mt-5"
            id="document_materiel_suivi"
            url="/upload/document"
            title="Vos fichiers"
            :file_system="'MATERIEL'"
            :file_field="'materiel_suivi'"
            :file_key="materiel.id"
          ></uploader>
        </div>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapMultiRowFields } from "vuex-map-fields";

import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import MaterielsItemVerif from "@/components/materiels/MaterielsItemVerif.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "MaterielsItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseSelect,
    BaseInputDatePicker,
    BaseInputArea,
    MaterielsItemVerif,
    BaseToolBar,
    BaseButton,
    SelectCollaborateur,
    BaseIcon,
    Uploader,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["materiel"],
  computed: {
    ...mapGetters({ data: "materiel/data" }),
    ...mapMultiRowFields("materiel", ["materielsVerifs", "fournisseurs"]),
    verifs: function () {
      return this.materielsVerifs.filter((verif) => verif.materiel_id === this.materiel.id);
    },
  },
  methods: {
    ...mapActions({
      _updateMateriel: "materiel/updateMateriel",
      _deleteMateriel: "materiel/deleteMateriel",
      _storeMaterielVerif: "materiel/createMaterielVerif",
    }),
    getLastVerifDate() {
      if (this.verifs.length < 1) return null;
      if (this.verifs[0].date_validite == undefined) return null;
      let dt = this.$moment(this.verifs[0].date_validite);
      this.verifs.forEach((verif) => {
        if (verif && verif.date_validite && this.$moment(verif.date_validite) > dt) {
          dt = this.$moment(verif.date_validite);
        }
      });
      return dt;
    },
    storeMaterielVerif: function () {
      this.feedback = {};
      this.loading = true;
      this._storeMaterielVerif(this.materiel)
        //.catch(error => ())
        .finally(() => (this.loading = false));
    },
    updateMateriel: function (materiel) {
      this.feedback = {};
      this.loading = true;
      this._updateMateriel(materiel)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteMateriel: function (materiel) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteMateriel(materiel)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
