<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Materiels" subtitle="Liste des matériel disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Exporter les matériels'"
        @click="exportMateriels"
        :loading="loading"
        text="Exporter (.xls)"
      ></BaseButton>
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un matériel'"
        @click="storeMateriel"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-lg-row mb-3">
      <filtre-select
        class="mr-md-4"
        inputText="Type d'appareil"
        v-model.number="typeFilter"
        :options="data.materielTypes"
        :disabled="false"
      ></filtre-select>
      <filtre-select
        class="mr-md-4 mt-3 mt-lg-0"
        inputText="Etat"
        v-model.number="etatFilter"
        :options="data.materielEtats"
        :disabled="false"
      ></filtre-select>
      <filtre-select
        class="mt-3 mt-lg-0"
        inputText="Sociétés"
        v-model.number="societeFilter"
        :options="data.societes"
        :disabled="false"
      ></filtre-select>
    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="materiel in materielsFiltered" :key="'actives_' + materiel.id">
          <materiels-item :materiel="materiel"></materiels-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import MaterielsItem from "@/components/materiels/MaterielsItem.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import FiltreSelect from "@/components/bases/FiltreSelect.vue";

export default {
  components: {
    BaseToolBar,
    BaseButton,
    MaterielsItem,
    FiltreSelect,
  },
  data() {
    return {
      loading: false,
      feedback: {},
      typeFilter: null,
      etatFilter: null,
      societeFilter: null,
    };
  },
  methods: {
    ...mapActions({
      _storeMateriel: "materiel/createMateriel",
      _exportMateriels: "materiel/exportMateriels",
    }),
    storeMateriel: function () {
      this.feedback = {};
      this.loading = true;
      this._storeMateriel(this.newMateriel)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    exportMateriels: function () {
      this.loading = true;
      this._exportMateriels().finally(() => (this.loading = false));
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({ data: "materiel/data" }),
    ...mapMultiRowFields("materiel", ["materiels"]),
    materielsFiltered() {
      let mats = this.materiels;
      if (this.typeFilter) mats = mats.filter((materiel) => materiel.materiel_type_id == this.typeFilter);
      if (this.etatFilter) mats = mats.filter((materiel) => materiel.materiel_etat_id == this.etatFilter);
      if (this.societeFilter) mats = mats.filter((materiel) => materiel.materiel_societe_id == this.societeFilter);
      return mats;
    },
  },
};
</script>
<style lang="css"></style>
