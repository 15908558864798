<template>
  <div class="d-inline" @keyup.esc="hide">
    <div class="d-flex align-items-center">
      <slot name="title"></slot>
      <button :class="btnClass" @click="show">
        <span class>{{ btn }}</span>
      </button>
    </div>
    <div class="qwark-modal-backdrop w-100 h-100" v-if="displayForm" @click="hide"></div>
    <transition name="fade" mode="out-in">
      <div v-if="displayForm">
        <div class="qwark-modal" :id="id" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content shadow" v-background-3>
              <div class="modal-header border-0">
                <div class>
                  <h4 class="m-0">{{ title }}</h4>
                </div>
                <button type="button" class="close" @click="displayForm = false">
                  <span aria-hidden="true" :class="text">&times;</span>
                </button>
              </div>
              <slot name="form"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      displayForm: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    btn: {
      type: String,
      required: false,
      default: "Ajouter",
    },
    title: {
      type: String,
      required: false,
      default: "Titre",
    },
    btnClass: {
      type: String,
      required: false,
      default: "btn btn-outline-primary ml-2",
    },
  },
  methods: {
    show: function () {
      this.displayForm = true;
      this.$emit("modal-open");
      //document.body.classList.add("modal-open");
    },
    hide: function () {
      this.displayForm = false;
      //document.body.classList.remove("modal-open");
    },
  },
  computed: {
    text: function () {
      if (this.$store.getters["colors/darkMode"]) {
        return "text-white";
      }
      return "text-black";
    },
  },
  mounted() {
    // document.body.classList.add("modal-open");
  },
  destroy() {
    //document.body.classList.remove("modal-open");
  },
};
</script>
<style lang="css">
.qwark-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
}

.qwark-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
