<template>
  <div v-background-3 class="shadow-sm rounded w-100 p-3">
    <select
      :id="id"
      v-bind="$attrs"
      autocomplete="off"
      :class="colorClass"
      :value="value"
      @input="change($event.target.value)"
    >
      <option :value="null" :disabled="disabled">{{ inputText }}</option>
      <option v-for="option in options" :value="option.id" :key="option.id">{{
        option[field]
      }}</option>
    </select>
  </div>
</template>
<script>
export default {
  name: "BaseInput",
  components: {},
  inheritAttrs: false,
  data() {
    return {
      id: null
    };
  },
  props: {
    inputText: {
      type: String,
      default: "label"
    },
    options: {
      type: [Object, Array],
      default: () => {
        return {};
      }
    },
    value: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: true
    },
    field: {
      type: String,
      default: "description"
    }
  },

  methods: {
    change: function(value) {
      if (value === 0 || value === null || value === "") {
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    editable: function() {
      if (this.$attrs.disabled === undefined) {
        return false;
      } else {
        return true;
      }
    },

    colorClass: function() {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "text-dark icon-select-dark";
      } else {
        return "text-white icon-select-white";
      }
    }
  }
};
</script>
<style lang="css" scoped>
select {
  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  appearance: none;
  background-color: transparent;
  border: 0;
  border-bottom: none;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  height: 2.2rem;
  outline: 0;
  text-indent: 0.01px; /* Removes default arrow from firefox*/
  text-overflow: ""; /*Removes default arrow from firefox*/ /*My custom style for fonts*/
}

.icon-select-dark {
  background: url("data:image/svg+xml;utf8,<svg width='22px' height='22px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='fill:none;fill-rule:nonzero;stroke:grey;stroke-width:2px;'/></svg>")
    no-repeat right 0.05rem center/22px 22px;
}

.icon-select-white {
  background: url("data:image/svg+xml;utf8,<svg width='22px' height='22px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='fill:none;fill-rule:nonzero;stroke:white;stroke-width:2px;'/></svg>")
    no-repeat right 0.05rem center/22px 22px;
}
</style>
