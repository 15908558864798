<template>
  <div>
    <Modal :id="'modal_' + verif.id" :title="'vérification n°' + verif.id" btn="Ouvrir">
      <template v-slot:title>
        <div class="mt-1 mb-1 p-2 rounded d-flex flex-row align-items-center flex-grow-1" v-background-1>
          <div class="d-flex flex-column">
            <description-from-list
              :value="verif.verif_type_id"
              :data="data.materielVerifTypes"
              text="Nouvelle vérification"
              class="text-truncate mr-1"
            ></description-from-list>
            <div v-if="verif.date_validite" class="mr-3">
              valide jusqu'au {{ $moment(verif.date_validite).format("DD/MM/YYYY") }}
            </div>
          </div>
          <div class="ml-auto">
            <BaseIcon
              name="circle"
              width="10"
              height="10"
              class="text-success m-auto"
              fill="#28a745"
              v-if="$moment(verif.date_validite) >= $moment()"
            ></BaseIcon>
            <BaseIcon
              name="circle"
              width="10"
              height="10"
              class="text-danger m-auto"
              fill="#dc3545"
              v-if="$moment(verif.date_validite) < $moment()"
            ></BaseIcon>
          </div>
        </div>
      </template>
      <template v-slot:form>
        <base-form
          deleteBtn
          @click="updateMaterielVerif(verif)"
          @delete="deleteMaterielVerif(verif)"
          :loading="loading"
        >
          <base-form-row row>
            <BaseSelect
              inputText="Type"
              v-model.number="verif.verif_type_id"
              :options="data.materielVerifTypes"
              :errors="feedback.verif_type_id"
            ></BaseSelect>
          </base-form-row>
          <base-form-row row v-if="!verif.verif_interne">
            <base-select
              inputText="Fournisseur pour la vérification"
              v-model.number="verif.fournisseur_id"
              :options="data.fournisseurs"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <Checkbox
              id="1"
              v-model="verif.verif_interne"
              text="Vérification en interne"
              :errors="feedback.verif_interne"
              :iconActive="true"
            ></Checkbox>
          </base-form-row>
          <base-form-row row>
            <base-input-date-picker
              v-model="verif.date_debut"
              inputText="Date de vérification"
              :errors="feedback.date_debut"
            ></base-input-date-picker>
          </base-form-row>
          <base-form-row row>
            <base-input-date-picker
              v-model="verif.date_validite"
              inputText="Date de validité"
              :errors="feedback.date_validite"
            ></base-input-date-picker>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="verif.commentaire"
              text="Commentaire"
              :errors="feedback.commentaire"
            ></base-input-area>
          </base-form-row>
        </base-form>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import Modal from "@/components/bases/Modal2.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import Checkbox from "@/components/bases/Checkbox.vue";

export default {
  components: {
    Modal,
    BaseForm,
    BaseFormRow,
    BaseIcon,
    BaseSelect,
    BaseInputDatePicker,
    BaseInputArea,
    DescriptionFromList,
    Checkbox,
  },
  data() {
    return {
      feedback: {},
      loading: false,
    };
  },
  props: ["verif"],
  computed: {
    ...mapGetters({ data: "materiel/data" }),
  },
  methods: {
    ...mapActions({
      _updateMaterielVerif: "materiel/updateMaterielVerif",
      _deleteMaterielVerif: "materiel/deleteMaterielVerif",
    }),
    updateMaterielVerif: function (verif) {
      this.feedback = {};
      this.loading = true;
      this._updateMaterielVerif(verif)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteMaterielVerif: function (verif) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteMaterielVerif(verif)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
};
</script>
<style lang="css"></style>
